.work-history-timeline {
    align-items: center;
}

.work-history-timeline-dot {
    border-color: #a41d30!important;
}

.work-history-timeline-dot:hover {
    background: #a41d30;
}

.work-history-icon {
    width: 25px!important;
    height: 25px!important;
    padding: 2px;
    color: #a41d30;
}

.work-history-timeline-dot:hover .work-history-icon {
    color: white;
}

.work-history-timeline-connector {
    background-color: darkgrey;
}

.work-history-timeline-paper {
    padding: 6px 16px;
    background: #e7e7e7!important;
}

.work-history-year-employed {
    font-size: 20px!important;
    font-weight: bold!important;
    display: inline;
    padding: 2px 4px;
    color: white;
    border-radius: 5px;
}

.work-history-company-name {
    font-size: 20px!important;
    font-weight: bold!important;
}

.work-history-job-title {
    font-style: italic;
}
