.proj-card {
    position: relative;
    border: solid #801726 6px;
    border-radius: initial!important;
    background: #801726!important;
    outline: solid rgba(164,29,48, .2) 8px;
}

.proj-card:hover .proj-card-media  {
    z-index: -1;
    transition-delay: 1s;
}

.proj-card-media {
    position: relative;
    z-index: 1;
}

.proj-card-media:hover {
    -webkit-animation: 1s ease 0s normal forwards 1 fadein;
    animation: 1s ease 0s normal forwards 1 fadein;
}

.proj-card-desc {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    color: white;
    text-align: center;
    font-weight: bold!important;
    padding: 5px!important;
}

.proj-card-avatars {
    position: absolute;
    z-index: 0;
    justify-content: center;
    bottom: 2%;
    left: 0;
    right: 0;
}

@keyframes fadein{
    100% { opacity:0; }
    66% { opacity:0; }
    0% { opacity:1; }
}

@-webkit-keyframes fadein{
    100% { opacity:0; }
    66% { opacity:0; }
    0% { opacity:1; }
}

@media (max-width: 1279px) {
    .proj-card-desc {
        font-size: 1.8vw!important;
    }
}

@media (max-width: 959px) {
    .proj-card-desc {
        font-size: 2.6vw!important;
    }
}

@media (max-width: 599px) {
    .proj-card-desc {
        font-size: 5.7vw!important;
    }
}
