.overview-card {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: 1s !important;
}

.overview-card:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.475);
}

.overview-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #777;
}

.overview-card-icon {
    text-align: center;
    font-size: 100px;
    padding: 20px;
}

.overview-card-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 5px 0;
    color: #801726;
}

.overview-card-desc {
    text-align: center;
    font-size: 16px;
}
