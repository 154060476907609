.about-me-container {
    display: flow-root!important;
    align-items: center;
}

.about-me-avatar-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin: 40px 40px 40px 0!important;
    shape-outside: circle();
}

.about-me-avatar {
    width: 100%!important;
    height: auto!important;
    max-width: 180px;
    max-height: 180px;
    box-shadow: 0 0 2rem rgba(0, 0, 0, .35);
    opacity: .9;
}

.about-me-desc-grid {
    max-width: 100%!important;
}

.about-me-desc {
    font-size: 16px;
    font-style: italic;
}
